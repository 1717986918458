import './AboutMe.css';

const AboutMe = () => {
    return (
        <>
        <div className="hello">Hello</div>
        <h3>A Bit About Me</h3>
        <p>
            I'm a web developer <br/> specialiazed in <span className="pyth">Pyt</span>
            <span className="dj">hon </span>
            <span className="ngo">Django</span>{" "}
            <span className="react">
                React <span className="js">JS.</span>
            </span>
        </p>
        </>
    )
}

export default AboutMe